export const handlePointerLockChange = (hasPointerLockPermission: boolean): void => {
  if(hasPointerLockPermission){
    attemptPointerLock();
  }else{
    attemptPointerRelease();
  }
}

export const attemptPointerLock = () => {
  const videoArray = document.getElementsByTagName('video');
  if(videoArray.length===1){
    videoArray[0].requestPointerLock();
  }else{
    console.log('more than one video element, things are bad');
  }
}

export const attemptPointerRelease = () => {
  if(document.pointerLockElement){
    // Attempt to unlock
    document.exitPointerLock();
  }else{
    console.log('no pointer lock element found');
  }
}