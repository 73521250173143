import { ApeCityConfig } from './worlds/ape-city';
import { WorldConfig } from './world-config';
import { MetatravelersConfig } from './worlds/metatravelers';
import { MefaverseConfig } from './worlds/mefaverse';
import { WorldBuilderDemoConfig } from './worlds/world-builder-demo';

export const getConfigurationFromEnvironment = (): WorldConfig | undefined => {
  switch(process.env.REACT_APP_WORLDNAME){
    case 'ape-city':
      return ApeCityConfig;
    case 'metatravelers':
      return MetatravelersConfig;
    case 'mefaverse':
      return MefaverseConfig;
    case 'worldbuilder-demo':
      return WorldBuilderDemoConfig;
    default:
      console.log('no config defined for this world');
      return;
  }
}