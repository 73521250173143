import { Provider } from "@ethersproject/abstract-provider";
import { BigNumber, ethers } from "ethers";
import { WorldConfig } from "../configuration/world-config";

export type ContractCustomization = {
  useWallet: boolean;
  useContractAuth: boolean;
  useFoundersAuth: boolean;
  useAlphaFoundersAuth: boolean;
  useGoldnVipAuth: boolean;
  useRoyalVipAuth: boolean;

  useERC1155Contract: boolean;
  ERC1155Contract: string | null;
  ERC1155ContractAbi: string[] | null;

  useERC721Contract: boolean;
  ERC721Contract: string | null;
  ERC721ContractAbi: string[] | null;
}

export type ContractInfo = {
  type: ContractType;
  isUsed: boolean;
  address?: string;
  abi?: string[];
  tokenIds?: string[];
}

export enum ContractType { ERC721, ERC1155 }

// ERC 721
export const FOUNDERS_CONTRACT = "0xAdc53fB91e97B19Ff51CB9dD00e56Ffaab0Af9fC";
export const foundersAbi: string[] = [
  "function balanceOf(address owner) external view returns (uint256 balance)"
];

export const MACHINE_ELVES_CONTRACT = "0x240Ba10E17E3631109ed86432BF51DDc803cFB00";
export const machineElvesAbi: string[] = [
  "function balanceOf(address owner) external view returns (uint256 balance)"
];

export const ALPHA_FOUNDERS_CONTRACT = "0xe398Ec0C0e4131d32939792d1df7D3E4b2bc1791";
export const alphaFoundersAbi: string[] = [
  "function balanceOf(address owner) external view returns (uint256 balance)"
];

// ERC1155
export const ROYAL_VIP_CONTRACT = "0x0d9e3F0F76aEa454B694007f7AD79a77d89118D7";
export const royalVipAbi: string[] = [
  "function balanceOf(address owner, uint256 id) external view returns (uint256 balance)"
];

export const GOLDN_VIP_CONTRACT = "0x22ea856CaC6EE38173948954d4073BdcCccF8cb2";
export const goldnVipAbi: string[] = [
  "function balanceOf(address owner, uint256 id) external view returns (uint256 balance)"
];

// previous token ID 35270684224311018709948397593405681609830801076105913508413469037202830786561
export const RINKEBY_FAKE_ELVES = {
  contract: "0x1bEE410751aD1F08bA712846c2bE5ff21fA05C40",
  abi: [
    "function balanceOf(address owner, uint256 id) external view returns (uint256 balance)"
  ],
  tokenId: "35270684224311018709948397593405681609830801076105913508413469037202830786562"
}

export const getClientERC1155Contract = (
  provider: Provider, 
  useERC1155Contract: boolean,
  erc1155Contract?: string,
  erc1155ContractAbi?: string[]
) => {
  if(!provider) return;
  // no value if we're not using ERC1155
  if(!useERC1155Contract) return;
  // throw error if we are using ERC1155 but are not passing that info
  if(!erc1155Contract || !erc1155ContractAbi) throw new Error('missing ERC1155 configuration data');
  return new ethers.Contract(erc1155Contract, erc1155ContractAbi, provider);
}

export const getClientERC721Contract = (
  provider: Provider,
  useERC721Contract: boolean,
  erc721Contract?: string,
  erc721ContractAbi?: string[]
) => {
  if(!provider) return;
  // no value if you're not using ERC721
  if(!useERC721Contract) return;
  // throw error if we are using ERC721 but lack the configuration
  if(!erc721Contract || !erc721ContractAbi) throw new Error('missing ERC721 configuration data');
  return new ethers.Contract(erc721Contract, erc721ContractAbi, provider);
}

export const getFoundersContract = (provider: Provider) => {
  if(!provider) return;
  return new ethers.Contract(FOUNDERS_CONTRACT, foundersAbi, provider);
}
export const getMachineElvesContract = (provider: Provider) => {
  return new ethers.Contract(MACHINE_ELVES_CONTRACT, machineElvesAbi, provider);
}
export const getAlphaFoundersContract = (provider: Provider) => {
  return new ethers.Contract(ALPHA_FOUNDERS_CONTRACT, alphaFoundersAbi, provider);
}
export const getRoyalVipContract = (provider: Provider) => {
  return new ethers.Contract(ROYAL_VIP_CONTRACT, royalVipAbi, provider);
}
export const getGoldNVipContract = (provider: Provider) => {
  return new ethers.Contract(GOLDN_VIP_CONTRACT, goldnVipAbi, provider);
}
export const getRinkebyFakeElvesContract = (provider: Provider) => {
  return new ethers.Contract(RINKEBY_FAKE_ELVES.contract, RINKEBY_FAKE_ELVES.abi, provider);
}

export const checkClientContract = async (
  provider: Provider, 
  targetWallet: string,
  client721: ContractInfo,
  client1155: ContractInfo
) => {
  if(!provider) return false;
  // do the ERC721 first to avoid iterating over all ERC1155 ID slices
  if(client721.isUsed){
    const clientContract = getClientERC721Contract(provider, client721.isUsed, client721.address, client721.abi);
    if(!clientContract){
      throw new Error('ERC721 contract unexpectedly undefined');
    }
    const erc721balance = await clientContract.balanceOf(targetWallet);
    console.log('erc721 balance',erc721balance);
    if(erc721balance && BigNumber.from(0).lt(erc721balance)){
      return true;
    }
  }

  if(client1155.isUsed){
    const contract = getClientERC1155Contract(provider, client1155.isUsed, client1155.address, client1155.abi);
    if(!contract || contract===undefined){
      throw new Error('ERC1155 contract unexpectedly undefined');
    }
    const arrayHolder = [];
    for(let i=0; i<10; i++){
      arrayHolder[i] = client1155.tokenIds?.slice(i*1000, i*1000+1000);
    }
    const walletArray = arrayHolder[0]?.map(item => targetWallet);
    const promiseList = arrayHolder.map((subList) => contract.balanceOfBatch(walletArray, subList));
    try{
      const responseList = await Promise.all(promiseList);
      let balanceList: BigNumber[] = [];
      responseList.forEach((responseArray) => {
        balanceList = balanceList.concat(responseArray.filter((balance: BigNumber) => BigNumber.from(0).lt(balance)));
      });
      return balanceList.length>0;
    }catch(err){
      console.error('error checking that you hold the associated ERC1155 NFT');
      return false;
    }
  }
  return false;
}

export const checkFounders = async (provider: Provider, targetWallet: string) => {
  if(!provider) return false;
  // retain this code for future use
  const contract = getFoundersContract(provider);
  if(!contract){
    throw new Error('Founders Contract unexpectedly undefined');
  }
  const tokenBalance = await contract.balanceOf(targetWallet);
  return BigNumber.from(0).lt(BigNumber.from(tokenBalance));
}
export const checkMachineElves = async (provider: Provider, targetWallet: string) => {
  const contract = getMachineElvesContract(provider);
  if(!contract){
    throw new Error('Machine Elves Contract unexpectedly undefined');
  }
  const tokenBalance = await contract.balanceOf(targetWallet);
  return BigNumber.from(0).lt(BigNumber.from(tokenBalance));
}
export const checkAlphaFounders = async (provider: Provider, targetWallet: string) => {
  const contract = getAlphaFoundersContract(provider);
  if(!contract){
    throw new Error('Alpha Founders Contract unexpectedly undefined');
  }
  const tokenBalance = await contract.balanceOf(targetWallet);
  return BigNumber.from(0).lt(BigNumber.from(tokenBalance));
}
export const checkRoyalVips = async (provider: Provider, targetWallet: string) => {
  const contract = getRoyalVipContract(provider);
  if(!contract){
    throw new Error('Royal VIP Contract unexpectedly undefined');
  }
  // royal VIP token ID is 1 on the contract
  const tokenBalance = await contract.balanceOf(targetWallet, 1);
  return BigNumber.from(0).lt(BigNumber.from(tokenBalance));
}
export const checkGoldnVips = async (provider: Provider, targetWallet: string) => {
  const contract = getGoldNVipContract(provider);
  if(!contract){
    throw new Error('GoldN VIP Contract unexpectedly undefined');
  }
  // goldn vip is token 1 on the contract
  const tokenBalance = await contract.balanceOf(targetWallet, 1);
  return BigNumber.from(0).lt(BigNumber.from(tokenBalance));
}
export const checkRinkebyFakeElves = async (provider: Provider, targetWallet: string) => {
  const contract = getRinkebyFakeElvesContract(provider);
  if(!contract){
    throw new Error(`Rinkeby Fake Elves Contract unexpectedly undefined`);
  }
  const tokenBalance = await contract.balanceOf(targetWallet, RINKEBY_FAKE_ELVES.tokenId);
  return BigNumber.from(0).lt(BigNumber.from(tokenBalance));
}

// specific wallet check
export const checkApprovedWallet = (targetWallet: string, adminWalletList: string[]): boolean => {
  return adminWalletList.indexOf(targetWallet)>-1;
}

// check against any of the LeapN contracts based upon a passed in ContractCustomization object
// handles Founders, Alpha Founders, Goldn VIP and Royal VIP
export const checkLeapnApproval = async (worldConfig: WorldConfig, provider: Provider, targetWallet: string) => {
  // each option defaults to false (if it's not set to be used)
  // but if it is to be used, then we will get that value
  // and if any of the contracts returns true, we should authorize the user
  const founder = worldConfig.useFoundersAuth ? await checkFounders(provider, targetWallet) : false;
  const alphaFounder = worldConfig.useAlphaFoundersAuth ? await checkAlphaFounders(provider, targetWallet): false;
  const goldnVip = worldConfig.useGoldnVipAuth ? await checkGoldnVips(provider, targetWallet) : false;
  const royalVip = worldConfig.useRoyalVipAuth ? await checkRoyalVips(provider, targetWallet) : false;
  return ( founder || alphaFounder || goldnVip || royalVip );
}