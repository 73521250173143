import React from 'react';
import './TermsConditionsLabel.css';

type TermsConditionsLabelProps = {
  textColor: string;
  linkColor: string;
}
const TermsConditionsLabel = (props: TermsConditionsLabelProps) => (
  <label className="terms_conditions" htmlFor="termsCheckbox" style={{color: props.textColor}}>
    I have read and agree to the {' '}
    <a href="https://leapn.life/terms.html" target="_blank" rel="noreferrer" style={{color: props.linkColor}}>Terms of Use</a>
    {' '}and{' '}
    <a href="https://leapn.life/privacy.html" target="_blank" rel="noreferrer" style={{color: props.linkColor}}>Privacy Policy</a>
  </label>
);

export default TermsConditionsLabel;