import React from 'react';
import { Landing } from '../Landing';
import "./NoTokenMessage.css";

type NftLinkProps = {
  link: string;
  nftName: string;
  openSeaLogoSize: number|string;
}
const NftLink = (props: NftLinkProps) => {
  const { link, nftName, openSeaLogoSize } = props;
  return (
    <div className="nft_link">
      <img src="/opensea.png" width={openSeaLogoSize} alt="opensea"/>
      <a href={link} target="_blank" rel="noreferrer">
        Go to {nftName} NFT Collection
      </a>
    </div>
  )
}

export type NoTokenMessageProps = {
  backgroundImage: string;
  clientLogo: string;
  logoAlt: string;
  leapnLogo: string;
  worldName: string;
  customText?: string;
  useFounders: boolean;
  useAlphaFounders: boolean;
  useGoldnVip: boolean;
  useRoyalVip: boolean;
  useErc1155: boolean;
  useErc721: boolean;
  hideLeapnLogo?: boolean;
  clientNfts: {name: string, collectionUrl: string}[]|undefined
}
export const NoTokenMessage = (props: NoTokenMessageProps) => {
  const { backgroundImage, clientLogo, leapnLogo, worldName, logoAlt, clientNfts, hideLeapnLogo } = props;
  const landingProps = { backgroundImage, clientLogo, leapnLogo, worldName, logoAlt, hideLeapnLogo };
  const openSeaLogoSize = '2em';
  const listItemHeight = '3em';

  const accessArray = [];
  const linkList = [];
  if(clientNfts){
    clientNfts.forEach((clientNft) => {
      accessArray.push(clientNft.name);
      linkList.push({ openSeaLogoSize, link: clientNft.collectionUrl, nftName: clientNft.name });
    });
  }
  if(props.useFounders){
    accessArray.push('LeapN Founders Deed World');
    linkList.push({ openSeaLogoSize, link: 'https://opensea.io/collection/leapn-founders-deed-world', nftName: 'Leapn Founders' });
  }
  if(props.useAlphaFounders){
    accessArray.push('LeapN Alpha Founders');
    linkList.push({ openSeaLogoSize, link: 'https://opensea.io/collection/leapn-alpha-deeds', nftName: 'LeapN Alpha Founders'})
  }
  if(props.useGoldnVip){
    accessArray.push('LeapN GoldN VIPs');
    linkList.push({ openSeaLogoSize, link: 'https://opensea.io/collection/leapn-vip-badges', nftName: 'LeapN GoldN VIPs' });
  }
  if(props.useRoyalVip){
    accessArray.push('LeapN Royal VIPs');
    linkList.push({ openSeaLogoSize, link: 'https://opensea.io/collection/leapn-royal-vip', nftName: 'LeapN Royal VIPs' });
  }

  return (
    <Landing {...landingProps}>
      <div className="no_token_div">
        { accessArray.length===1 ? (
          <p>Only { accessArray[0] } NFT holders can enter { worldName }.</p>
        ) : (
          <p>{ worldName } access is restricted to wallets holding one of the following NFTs: { accessArray.join(', ') }</p>
        )}
        { props.customText && (<p>{ props.customText }</p>) }
        <ul>
          {
            linkList.map((item, i) => (
              <li style={{height: listItemHeight}} key={'linkItem'+i}>
                <NftLink {...item} />
              </li>
            ))
          }
        </ul>
      </div>
    </Landing>
  )
}

// export default NoTokenMessage;