// iframe holder for the game itself
import React, { useEffect, useState } from 'react';
import TopBarButton from '../TopBarButton';
import LoadingInformation from '../LoadingInformation';
import TopLeftStack from '../TopLeftStack';

export type GameProps = {
  streamUrl: string;
  showPlayButton: boolean;
  acquirePercent: number;
  prepPercent: number;
  queuePosition: number;
  isFull: boolean;
  loadingImage: string;
  displayAddress: string|undefined;
  hideTeleport?: boolean;
  hideWallet?: boolean;
  hideTopLeftLeapn?: boolean;
  worldName: string;
  leapnLogo: string;
  logoAlt: string;
  initiateGame: () => void;
}
export const Game = (props: GameProps) => {
  const [gameReady, setGameReady] = useState(false);
  const getDisplayValue = () => (
    gameReady ? 'visible' : 'hidden'
  );
  // variable positioning to make it eligible for z-index
  const getPositionValue = () => ( gameReady ? 'static' : 'absolute' )

  const triggerGame = () => {
    setGameReady(true);
    props.initiateGame();
  }

  // prevent the awkward showing of game component
  // after loading 3_2 but not yet having the game loaded
  // useEffect(() => {
  //   setGameReady(props.acquirePercent===100 && props.prepPercent===100);
  // }, [props.acquirePercent, props.prepPercent]);

  useEffect(() => {
    if(props.showPlayButton){
      triggerGame();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.showPlayButton]);

  const gameStyle = {
    height: '100vh',
    width: '100vw',
    backgroundImage: `url(${props.loadingImage})`,
    backgroundSize: 'cover',
    zIndex: 2,
    overflow: 'hidden'
  };

  return (
    <div style={gameStyle}>
      { (!gameReady && !props.showPlayButton) && ( 
        <LoadingInformation 
          queuePosition={props.queuePosition} 
          acquirePercent={props.acquirePercent} 
          prepPercent={props.prepPercent} 
          isFull={props.isFull}
        />
      )}
      { !props.hideTopLeftLeapn && (
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'start',
          height: 'auto',
          position: 'absolute',
          top: 0,
          left: 0
        }}>
          <TopLeftStack worldName={props.worldName} logoImage={props.leapnLogo} logoAlt={props.logoAlt}/>
        </div>
      )}
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        position: 'absolute',
        top: '1vh',
        right: '1vw',
        zIndex: 5,
        gap: '1em'
      }}>
        { !props.hideTeleport && (
          <a
            href='https://leapn.life/teleport' 
            target="_blank"
            rel="noreferrer"
          >
            <TopBarButton>
              Teleport Now
            </TopBarButton>
          </a>
        )}
        { props.displayAddress && (
          <TopBarButton>{props.displayAddress}</TopBarButton>
        )}
      </div>
      <iframe 
        id="game_frame"
        title="game_window"
        src={props.streamUrl}
        width="100%" 
        height="100%"
        style={{visibility: getDisplayValue(), position: getPositionValue(), top: 0}}
        allowFullScreen>
      </iframe>
    </div>
  );
}