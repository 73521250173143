import React from 'react';
import TopLeftStack from '../TopLeftStack';

export type LandingProps = {
  backgroundImage: string;
  clientLogo: string;
  leapnLogo: string;
  logoAlt: string;
  worldName: string;
  children?: React.ReactNode;
  hideWorldLogo?: boolean;
  hideLeapnLogo?: boolean;
}
export const Landing = (props: LandingProps) => {
  const styleObject = {
    display: 'flex',
    flexDirection: 'column' as 'column', // css hack for typescript
    backgroundImage: `url(${props.backgroundImage})`, // dynamically point at the linked file
    backgroundSize: 'cover',
    height: '100vh',
    width: '100vw',
    justifyContent: 'center',
    alignItems: 'center'
  };
  
  return (
    <div style={styleObject}>
      { !props.hideLeapnLogo && (
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0
        }}>
          <TopLeftStack worldName={props.worldName} logoImage={props.leapnLogo} logoAlt={props.logoAlt}/>
        </div>
      )}
      {/* center */}
      { props.children }
      {/* bottom */}
      { !props.hideWorldLogo && (
        <div style={{
          position: 'absolute',
          bottom: 0,
          left: 0
        }}>
          <img src={props.clientLogo} alt={props.worldName} width={400} style={{padding: '1em'}}/>
        </div>
      )}
    </div>
  )
}