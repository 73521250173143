import React from 'react';
import './ConnectButton.css';

type ConnectButtonProps = {
  termsAccepted: boolean;
  connectMetamask: () => void;
  backgroundColor: string;
  color: string;
}
const ConnectButton = (props: ConnectButtonProps) => {

  return (
    <>
      <button className="connect_button" onClick={props.connectMetamask} disabled={!props.termsAccepted} style={{ 
        backgroundColor: props.backgroundColor,
        color: props.color,
      }}>Connect to Metamask</button>
    </>
  )
}

export default ConnectButton;