import { createClient } from '@supabase/supabase-js'
import { SUPABASE_TIMEOUT } from './constants';

const supabaseUrl = "https://norgfhvldzhcqnydzrfs.supabase.co";
const supabaseAnonKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlhdCI6MTY0NDAwNzEwMiwiZXhwIjoxOTU5NTgzMTAyfQ.AGP2MxhswEXCSrAnSefyCSnFH1nr8t60i7C-on-eUIo"
const activityTable = "activity";

// this is all built for ape gang, but if you match the (simple) schema this should work based upon activityTable

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

export type UserRecord = {
  id: number;
  game_world: string;
  user_nonce: string;
  last_seen: string;
}

export const queryActiveUsers = async (gameWorld: string, address: string) => {
  const queryTime = new Date(new Date().valueOf()-SUPABASE_TIMEOUT).toISOString();
  const { data, error } = await supabase
    .from(activityTable)
    .select('id, last_seen, address')
    .eq('game_world', gameWorld)
    .gt('last_seen', queryTime);
  if(error){
    console.error('error',error);
    return;
  }else{
    if(data===undefined || !data) return undefined;
    return data.length;
  }
}

export const checkAddressSessions = async (
  gameWorld: string, 
  address: string, 
  userNonce: string|undefined
): Promise<boolean> => {
  const queryTime = new Date(new Date().valueOf()-SUPABASE_TIMEOUT).toISOString();
  if(userNonce===undefined){
    const { data, error } = await supabase
      .from(activityTable)
      .select('id, address')
      .eq('game_world', gameWorld)
      .gt('last_seen', queryTime)
      .eq('address', address);
    if(error){
      console.error('error',error);
      return false;
    }else{
      console.log('nonce free',data);
      if(data===undefined || !data) return false;
      return data.length>0;
    }
  }else{
    const { data, error } = await supabase
      .from(activityTable)
      .select('id, address')
      .eq('game_world', gameWorld)
      .gt('last_seen', queryTime)
      .eq('address', address)
      .neq('user_nonce',userNonce);
    if(error){
      console.error('error',error);
      return false;
    }else{
      console.log('nonced data',data);
      if(data===undefined || !data) return false;
      return data.length>0;
    }
  }
}

export const createUserRecord = async (
  gameWorld: string, 
  userNonce: string,
  address: string
) => {
  const { data, error } = await supabase
    .from(activityTable)
    .insert([
      { user_nonce: userNonce, game_world: gameWorld, address: address }
    ]);
  if(error){
    console.error('error creating',error);
    return;
  }else{
    return data;
  }
}

export const updateUserRecord = async (gameWorld: string, userNonce: string) => {
  // const { data, error } = await supabase
  const { error } = await supabase
    .from(activityTable)
    .update({ 'last_seen': new Date() })
    .match({ user_nonce: userNonce, game_world: gameWorld });
  if(error){
    console.error('error updating user',error);
  }else{
    // console.log('update response',data);
  }
}

export const removeUserRecord = async (gameWorld: string, userNonce: string) => {
  // const { data, error } = await supabase
  const { error } = await supabase
    .from(activityTable)
    .delete()
    .match({ user_nonce: userNonce, game_world: gameWorld });
  if(error){
    console.error(`error deleting ${userNonce} from table ${activityTable}`);
  }else{
    // console.log(`deleted ${userNonce} from ${activityTable}`);
  }
}