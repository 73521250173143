import React from 'react';
import './TermsConditions.css';

import TermsConditionsLabel from '../TermsConditionsLabel';

type TermsConditionsProps = {
  textColor: string;
  linkColor: string;
  termsAccepted: boolean;
  checkboxBackground: string;
  checkedColor: string;
  changeHandler: () => void;
}
const TermsConditions = (props: TermsConditionsProps) => {

  const checkboxStyle = `input[type="checkbox"]:checked + *::before {
    content: "✔";
    color: white;
    text-align: center;
    background: ${props.checkedColor};
    border-color: ${props.checkboxBackground};
  }
  input[type="checkbox"]:checked + * {
    color: ${props.checkboxBackground};
  }`;
  
  const toggleTerms = () => {
    props.changeHandler();
  }
 
  return(

    <div style={{
      display: 'flex', 
      flexDirection: 'row', 
      marginTop: '1em',
    }}>
      <style>{checkboxStyle}</style>
      <input 
        type="checkbox" 
        id="termsCheckbox" 
        onChange={toggleTerms}
        checked={props.termsAccepted}
        style={{
          backgroundColor: props.checkboxBackground,
          padding: '1em',
          borderRadius: '0.5em',
        }}/>
      <TermsConditionsLabel textColor={props.textColor} linkColor={props.linkColor}/>
    </div>
  );
}

export default TermsConditions;