import React, { useState, useEffect } from 'react';
import './LoadingInformation.css';
// import Spinner from '../Spinner';
import { BallTriangle } from 'react-loader-spinner';

type ProgressPercentProps = {
  progressText: string;
  loadPercent: number;
}
const ProgressPercent = (props: ProgressPercentProps) => {
  return (<h2>{props.progressText}: {props.loadPercent.toFixed(0)}%</h2>)
}

type LoadingInformationProps = {
  queuePosition: number;
  acquirePercent: number;
  prepPercent: number;
  isFull: boolean;
}
const LoadingInformation = (props: LoadingInformationProps) => {
  const [showFull, setShowFull] = useState(false);
  const [showPrep, setShowPrep] = useState(false);
  const [showLeapingIn, setShowLeapingIn] = useState(false);

  useEffect(() => {
    setShowFull(props.isFull && !props.queuePosition);
  }, [props.isFull, props.queuePosition]);
  useEffect(() => {
    setShowPrep(props.prepPercent>0 || props.acquirePercent===100);
    setShowLeapingIn(props.prepPercent===100 && props.acquirePercent===100);
  }, [props.prepPercent, props.acquirePercent]);
  
  return (
    <div id="loading_information">
      {/* <Spinner/> */}
      <BallTriangle color="white" />
      { props.queuePosition>0 && (
        <div>
          <h1 style={{marginBottom: "16px"}}>Portal Queue: {props.queuePosition}</h1>
        </div>
      )}
      { props.acquirePercent>0 && (<ProgressPercent progressText="Locating World Destination" loadPercent={props.acquirePercent} />)}
      { showPrep && (<ProgressPercent progressText="Teleporting to World" loadPercent={props.prepPercent} />)}
      { showLeapingIn && (<h2>Leaping In Now...</h2>) }
      { showFull && (<h2>World capacity is full. Please try again soon.</h2>)}
    </div>
  )
}

export default LoadingInformation;