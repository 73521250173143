import Moralis from 'moralis';
// utility file for interacting with Moralis
const serverUrl = process.env.REACT_APP_MORALIS_SERVER;
const appId = process.env.REACT_APP_MORALIS_APP_ID;

export const getNftsForWalletAndContract = async (walletAddress: string, contractAddress: string): Promise<any[]> => {
  if(!serverUrl || !appId){
    console.error('invalid moralis configuration, check your .env file');
    return [];
  }
  
  try{
    await Moralis.start({ serverUrl, appId });
    const options = {
      address: walletAddress,
      token_address: contractAddress
    }
    const moralisResponse = await Moralis.Web3API.account.getNFTsForContract(options);
    if(moralisResponse===undefined){
      console.log('no moralis response');
      return [];
    }
    if(moralisResponse.result && moralisResponse.result.length > 0){
      return moralisResponse.result;
    }
  }catch(err){
    console.error(`error getting nfts for address ${walletAddress}: ${err}`);
  }
  return [];
}