import { WorldConfig } from '../world-config';
import apeTokenList from '../../world-specific/ape-gang/ape-token-ids.json';

export const ApeCityConfig: WorldConfig = {
  isTest: true,
  worldName: 'Ape City',
  logoAltText: 'Ape Gang',
  clientNfts: [
    {name: 'Ape Gang', collectionUrl: 'https://apegang.art'}
  ],
  controlsText: 'unused',
  adminWallets: [
    "0x557e5F91f60dc99aB499F6ffdbedFfBa44b441D9",
    "0x4DFA7FF4a33F2625495867a373caa4d165d37F37",
    "0xc36596E05312410fC41337eC6c2914A4FCA8d6C1",
    "0x033b13A18786C714e12fdEE8E62843bDf3b9224c",
    "0x102DD33ef3c1af8736EDdCc30985fEB69e099cD8",
    "0x1092B0b76574bd038aEEaccf57A6FD93551B60f7",
    "0x6D000551d4769A31e4c6f8e34F9a2B8e570BEb07",
    "0xb79a3a5BaCC45d4505d5E495d73FC871E4fB77C9",
    "0xcB013B53C27cA602A26C0ab722434A8316a192AD",
    "0x46F8351307482C162d7267F0fe08d5efd8Ba3BF1",
    "0x56dF5438eF630d6c7d8Cc872E55005f961131aFe",
    "0x0EbD8bED75AC0C2a30e0200FcBc51f9f2BE11369",
    "0x94DD54cfDFa8E5C179811Ac5F20Bf1A8Ba0BA658",
    "0x727e2847146186028Df2Cfc8628fcD61b41D6ad5"
  ],
  gameUrl: 'https://connector_prod.stream.leapn.life/v5/Ferb/ApeGangxx07xx03xx2022xxAshwinxxShip/ape-city',
  trackTokenList: true,
  // contract config
  contractCustomization: {
    useERC721Contract: true,
    ERC721ContractAddress: "0x2118fA9369b9a52fB6Bf8cF3fd392643d55a53B4",
    ERC721ContractAbi: [
      "function balanceOf(address _owner) external view returns (uint256 balance)"
    ],
    useERC1155Contract: true,
    ERC1155ContractAddress: "0x495f947276749Ce646f68AC8c248420045cb7b5e",
    ERC1155ContractAbi: [
      "function balanceOf(address _owner, uint256 _id) external view returns (uint256)",
      "function balanceOfBatch(address[] accounts, uint256[] ids) external view returns (uint256[])"
    ],
    tokenIdList: apeTokenList
  },
  useWallet: true,
  useContractAuth: true,
  useFoundersAuth: true,
  useAlphaFoundersAuth: true,
  useGoldnVipAuth: true,
  useRoyalVipAuth: false,
  checkboxBackground: 'transparent',
  textColor: '#333',
  buttonColor: '#456633',
  checkedColor: '#456633',
  linkTextColor: '#456633',
  buttonTextColor: 'white',
  maxPlayers: 15
}