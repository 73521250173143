export const tokenInList = (tokenId: string, tokenIdList: string[]|undefined): boolean => {
  if(tokenIdList===undefined) return false;
  for(let testId of tokenIdList){
    if(tokenId===testId){
      return true;
    }
  }
  return false;
}

export const safelyExtractApeNames = async (apeList: any[]) => {
  console.log('safely extract ape names',apeList);
  const tokenList = [];
  const failureIds = [];
  for(const ape of apeList){
    if(ape.metadata){
      const metadata = JSON.parse(ape.metadata);
      const apeNumber = Number.parseInt(metadata.name.split("#")[1].trim());
      tokenList.push(apeNumber);
    }else if(ape.token_uri){
      // fetch metadata from the URI
      console.log('fetching metadata from uri',ape.token_uri);
      try{
        const metaResponse = await fetch(ape.token_uri);
        // console.log('response',metaResponse);
        const metaJson = await metaResponse.json();
        // console.log('metaJson',metaJson);
        const apeNumber = Number.parseInt(metaJson.name.split("#")[1].trim());
        // console.log('resolved ape number',apeNumber);
        tokenList.push(apeNumber);
      }catch(err){
        console.log('error fetching token_uri metadata, unable to use ape with tokenId',ape.token_id,err);
        failureIds.push(ape.token_id);
      }
    }else{
      console.log('unable to resolve metadata for ape',ape);
      failureIds.push(ape.token_id);
    }
  }
  console.log('failed token resolutions',failureIds);
  return tokenList;
}