import React from 'react';
import { Landing } from '../Landing';

// this is a lot like Connect

type PopulationFailProps = {
  backgroundImage: string;
  clientLogo: string;
  leapnLogo: string;
  worldName: string;
  logoAlt: string;
  textColor?: string;
  hideWorldLogo?: boolean;
  hideLeapnLogo?: boolean;
  walletInUse?: boolean;
}
export const PopulationFail = (props: PopulationFailProps) => {
  const { backgroundImage, clientLogo, leapnLogo, worldName, logoAlt } = props;
  const hideWorldLogo = !!props.hideWorldLogo;
  const hideLeapnLogo = !!props.hideLeapnLogo;
  const landingProps = { backgroundImage, clientLogo, leapnLogo, worldName, logoAlt, hideWorldLogo, hideLeapnLogo };
  const textColor = props.textColor ?? 'white';

  const style = {
    color: textColor,
    marginBottom: 0
  }

  return (
    <Landing {...landingProps}>
      { props.walletInUse ? (
        <>
          <h3 style={style}>This wallet is already connected.</h3>
          <h3 style={style}>Please allow at least one minute to clear previous connection records, and try again.</h3>
        </>
        ) : (
          <>
            <h3 style={style}>
              Sorry, this World is currently at max occupancy.
            </h3>
            <h3 style={style}>Please wait for space to open, or try again later.</h3>
          </>
      )}
    </Landing>
  )
}

export default PopulationFail;