import React, { useState } from 'react';
import { Landing } from '../Landing';
import TermsConditions from '../TermsConditions';
import ConnectButton from '../ConnectButton';

type TermsAndConnectProps = {
  textColor: string;
  buttonTextColor: string;
  linkTextColor: string;
  checkboxBackground: string;
  buttonColor: string;
  checkedColor: string;
  connectMetamask: () => void;
}
const TermsAndConnect = (props: TermsAndConnectProps) => {
  const [termsAccepted, setTermsAccepted] = useState(false);

  const toggleTerms = () => {
    // console.log('connect toggling terms',termsAccepted);
    setTermsAccepted(!termsAccepted);
  }

  return (
    <div style={{
      display: 'flex', 
      flexDirection: 'column',
      alignItems: 'center',
      fontFamily: 'Lato,"Helvetica Neue",Arial,Helvetica,sans-serif',
    }}>
      <ConnectButton 
        termsAccepted={termsAccepted} 
        connectMetamask={props.connectMetamask }
        backgroundColor={props.buttonColor}
        color={props.buttonTextColor} />
      <TermsConditions 
        textColor={props.textColor} 
        termsAccepted={termsAccepted}
        checkboxBackground={props.checkboxBackground}
        checkedColor={props.checkedColor}
        linkColor={props.linkTextColor}
        changeHandler={toggleTerms}
      />
    </div>
  )
}

const ScanningWallet = (props: {textColor: string}) => {
  return (
    <div style={{
      display: 'flex', 
      flexDirection: 'column',
      alignItems: 'center',
      fontFamily: '"Cairo",sans-serif',
    }}>
      <h2 style={{color: props.textColor}}>Searching across the LeapNverse for your World...</h2>
    </div>
  )
}

export type ConnectProps = {
  backgroundImage: string;
  clientLogo: string;
  leapnLogo: string;
  worldName: string;
  logoAlt: string;
  buttonColor?: string;
  checkedColor?: string;
  checkboxBackground?: string;
  textColor?: string;
  buttonTextColor?: string;
  linkTextColor?: string;
  hideWorldLogo?: boolean;
  hideLeapnLogo?: boolean;
  scanningWallet?: boolean;
  isConnecting: boolean;
  connectMetamask: () => void;
}
export const Connect = (props: ConnectProps) => {
  const { backgroundImage, clientLogo, leapnLogo, worldName, logoAlt } = props;
  const hideWorldLogo = !!props.hideWorldLogo;
  const hideLeapnLogo = !!props.hideLeapnLogo;
  const landingProps = { backgroundImage, clientLogo, leapnLogo, worldName, logoAlt, hideWorldLogo, hideLeapnLogo };
  const textColor = props.textColor ?? 'white';
  const linkTextColor = props.linkTextColor ?? 'blue';
  const buttonTextColor = props.buttonTextColor ?? 'white';
  const checkboxBackground = props.checkboxBackground ?? 'rgba(0,0,0,0.2)'
  const buttonColor = props.buttonColor ?? 'blue';
  const checkedColor = props.checkedColor ?? buttonColor;  //default to match the button

  return (
    <Landing {...landingProps}>
      { (props.scanningWallet || props.isConnecting) ? 
        <ScanningWallet textColor={textColor}/> : 
        <TermsAndConnect 
          connectMetamask={props.connectMetamask} 
          checkboxBackground={checkboxBackground}
          buttonColor={buttonColor}
          checkedColor={checkedColor}
          buttonTextColor={buttonTextColor}
          linkTextColor={linkTextColor}
          textColor={textColor} />
      }
    </Landing>
  )
}

export default Connect;