import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Connect, Game, GameProps, NoTokenMessage, PopulationFail } from './components';

import { ethers } from 'ethers';
import { 
  checkClientContract, 
  checkApprovedWallet, 
  checkMachineElves,
  checkRinkebyFakeElves,
  ContractInfo,
  ContractType,
  checkLeapnApproval
} from './blockchain/ContractUtil';
// this is purely LeapN constants. Each world can also have its own list
import { LeapnAdminList } from './blockchain/AdminWallets';
import { getNftsForWalletAndContract } from './blockchain/MoralisUtil';
import { getConfigurationFromEnvironment } from './configuration/configuration-util';
import { WorldConfig } from './configuration/world-config';
import { tokenInList, safelyExtractApeNames } from './blockchain/util';
import { checkAddressSessions, createUserRecord, queryActiveUsers, removeUserRecord, updateUserRecord } from './population/SupabaseClient';
import { POPULATION_CHECK_INTERVAL, POPULATION_MAINTENANCE_INTERVAL } from './population/constants';
import { v4 as uuidv4 } from 'uuid';
import { handlePointerLockChange } from './pointer-control';
declare global {
  interface Window {
    ethereum: any;
    e3ds: {
      events: any;
      onEvent: (eventName: any, callback: any) => {}
    }
  }
  interface Document {
    mozExitPointerLock: any;
    requestPointerLock: any;
    mozRequestPointerLock: any;
  }
}

// NOTE requires inventory item from blockchain util
type WalletInfo = {
  address?: string;
  ensName?: string|undefined;
  hasElf?: boolean;
  hasClientToken?: boolean;
  allowAccess?: boolean;
  hasLoaded?: boolean;
  hasError?: boolean;
}

function App() {
  const [userInfo, setUserInfo] = useState<WalletInfo>({});
  const [queuePosition, setQueuePosition] = useState(0);
  const [acquisitionPercent, setAcquisitionPercent] = useState(0);
  const [preparationPercent, setPreparationPercent] = useState(0);
  const [showPlayButton, setShowPlayButton] = useState(false);
  const [isFull, setIsFull] = useState(false);
  const acquisitionRef = useRef(acquisitionPercent);
  const prepRef = useRef(preparationPercent);
  const queueRef = useRef(queuePosition);
  const [tokenList, setTokenList] = useState<any[]>();
  const [worldConfig, setWorldConfig] = useState<WorldConfig>();
  const [scanningWallet, setScanningWallet] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  // population control
  const [populationCount, setPopulationCount] = useState<number>();
  const [populationNonce, setPopulationNonce] = useState<string>();
  const [isLaunching, setIsLaunching] = useState(false);
  const populationNonceRef = useRef<any>(null);
  const keepAliveRef = useRef<any>(null);
  const populationCheckRef = useRef<any>(null);
  const [walletInUse, setWalletInUse] = useState(false);
  const [blockDisconnect, setBlockDisconnect] = useState(false);
  const [pointerLock, setPointerLock] = useState(false);
  const isLoginRunningRef = useRef(false);
  const holdingForData = useRef(false);

  // memoized "constants"
  const backgroundImage = useMemo(() => {
    switch(process.env.REACT_APP_WORLDNAME){
      case "ape-city":
        return require('./world-specific/ape-gang/landing.png');
      case "metatravelers":
        return require('./world-specific/metatravelers/landing.png');
      case "mefaverse":
        return require('./world-specific/mefaverse/landing.png');
      case "worldbuilder-demo":
        return require('./world-specific/worldbuilder/landing.png');
    }
  }, []);
  const clientLogo = useMemo(() => {
    switch(process.env.REACT_APP_WORLDNAME){
      case "ape-city":
        return require('./world-specific/ape-gang/client_logo.png');
      case "metatravelers":
        return require('./world-specific/metatravelers/client_logo.png');
      case "mefaverse":
        return require('./world-specific/mefaverse/client_logo.png');
      case "worldbuilder-demo":
        return require('./world-specific/worldbuilder/client_logo.png');
    }
  }, []);
  const loadingImage = useMemo(() => {
    switch(process.env.REACT_APP_WORLDNAME){
      case "ape-city":
        return require('./world-specific/ape-gang/loading.png');
      case "metatravelers":
        return require('./world-specific/metatravelers/loading.png');
      case "mefaverse":
        return require('./world-specific/mefaverse/loading.png');
      case "worldbuilder-demo":
        return require('./world-specific/worldbuilder/loading.png');
    }
  }, []);

  const adminWalletList = useMemo(() => {
    if(worldConfig?.adminWallets){
      const builtList = LeapnAdminList.concat(worldConfig.adminWallets);
      const adminSet = new Set(builtList);;
      return Array.from(adminSet);
    }
    return LeapnAdminList;
  }, [worldConfig?.adminWallets]);

  useEffect(() => {
    setWorldConfig(getConfigurationFromEnvironment());
  }, []);

  useEffect(() => {
    console.log('acq percent',acquisitionPercent);
    acquisitionRef.current = acquisitionPercent;
  }, [acquisitionPercent]);
  useEffect(() => {
    prepRef.current = preparationPercent;
  }, [preparationPercent]);
  useEffect(() => {
    queueRef.current = queuePosition;
  }, [queuePosition]);
  useEffect(() => {
    if(userInfo.address){
      populateTokenList(userInfo.address);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.address]);

  useEffect(() => {
    if(userInfo.address){
      getPlayerPopulation();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    handlePointerLockChange(pointerLock);
  }, [pointerLock])

  const sendToMainPage = (content: any) => {
    const origin = "*";
    const myIframe = document.getElementById("game_frame");
    if(myIframe){
      (myIframe as any).contentWindow.postMessage(JSON.stringify(content), origin);
    }else{
      console.log('iframe not found, cannot send', content);
    }
  }

  const calculateAndSendTokenList = async () => {
    console.log('process tokenList for token IDs');
    if(!tokenList) return;
    let nameList = [];
    let content = {};
    switch(process.env.REACT_APP_WORLDNAME){
      case "ape-city":
        // ugly
        nameList = await safelyExtractApeNames(tokenList);
        content = { apeList: nameList };
        break;
      case "metatravelers":
        nameList = tokenList.map((token) => token.token_id);
        content = { tokenList: nameList };
        break;
      case "mefaverse":
      case "worldbuilder-demo":
        console.log('not yet implemented');
        break;
    }
    console.log('sending to backend', { cmd: "sendToUe4", value: content });
    sendToMainPage({ cmd: "sendToUe4", value: content })
  }

  const handleGameMessage = (msg: any): void => {
    // console.log('message from engine',msg);
    // our server side messages (created by game engine)
    if(msg && msg.lockPointer!==undefined){
      console.log('set pointer lock by object attribute',msg);
      setPointerLock(msg.lockPointer);
    }
    // if(msg && typeof msg==='string' && msg.indexOf('lockPointer')>-1){
    //   console.log('set pointer lock based upon string content',msg);
    //   setPointerLock(JSON.parse(msg).lockPointer);
    // }
    if(msg.data && typeof msg.data==='string'){
      const messageContent = JSON.parse(msg.data);
      if(messageContent.cmd && messageContent.cmd==='jsonMessage'){
        console.log('backend sends',messageContent);
        if(messageContent.lockPointer!==undefined){
          setPointerLock(messageContent.lockPointer);
        }
        if(messageContent.url!==undefined){
          console.log('url message',messageContent.url);
          window.open(messageContent.url, "_blank");
        }
        if(messageContent.requestTokens){
          calculateAndSendTokenList();
          // sendToMainPage({ cmd: "sendToUe4", value: { tokenList } });
        }
      }
    }
    // if(msg.data && typeof msg.data==='string' && msg.data.indexOf('lockPointer')>-1){
    //   console.log('message data lockPointer',msg);
    //   const lockObject = JSON.parse(msg.data).lockPointer;
    //   setPointerLock(lockObject);
    // }
    // if(msg.url){
    //   console.log('url request');
    // }

    // if(msg.data.type===undefined) console.log('message without type',msg);
    // cut wrong format data
    if(msg.data.type===undefined) return;
    switch(msg.data.type){
      case "stage1_inqueued":
        // first step of loading
        console.log('checking queue');
        setTimeout(() => {
          if(queueRef.current===undefined){
            setIsFull(true);
          }
        }, 5000);
        break;
      case "stage2_deQueued":
        // out of that queue
        console.log('dequeued');
        break;
      case "stage3_slotOccupied":
        console.log('slot occupied');
        break;
      case "stage4_playBtnShowedUp":
        //loading screen 2 hides
        console.log('play button shows');
        if(!showPlayButton){
          setShowPlayButton(true);
        }
        break;
      case "stage5_playBtnPressed":
        // sidebar.style.visibility = "visible";
        console.log('user clicked play');
        setShowPlayButton(false);
        break;
      case "isIframe":
        let obj = {
          cmd: 'isIframe',
          value: true
        };
        sendToMainPage(obj);
        console.log('is iframe');
        break;
        
      case "QueueNumberUpdated":
        // console.log("QueueNumberUpdated. New queuePosition: : " +  event.data.queuePosition)
        console.log('new queue position',msg.data.queuePosition);
        setQueuePosition(Number.parseInt(msg.data.queuePosition));
        break;
        
      case "stage3_1_AppAcquiringProgress":
        // console.log("stage3_1_AppAcquiringProgress percent: " + JSON.stringify( event.data.percent))
        // console.log('new app acquisition progress',msg.data.percent);
        const inputNumber = Number.parseFloat(msg.data.percent);
        if(inputNumber<1){
          setAcquisitionPercent(inputNumber*100);
        }else{
          setAcquisitionPercent(inputNumber);
        }
        break;
        
      case "stage3_2_AppPreparationProgress":
        // console.log("stage3_2_AppPreparationProgress percent:",msg.data.percent);
        // setPreparationPercent(Number.parseFloat((msg.data.percent)));
        const prepNumber = Number.parseFloat(msg.data.percent);
        if(prepNumber<1){
          setPreparationPercent(prepNumber*100);
        }else{
          setPreparationPercent(prepNumber);
        }
        break;
      default:
        // need to add in expected but uninteresting things to reduce spam
        // if(process.env.NODE_ENV==='development'){
        //   console.log('DEV ONLY: unhandled message',msg);
        // }
    }
  }
  window.addEventListener("message", handleGameMessage);

  const handleLeapnMessage = async (data: any) => {
    console.log('received jsonMessage from engine',data);
    // handle pointer locks
    if(data.lockPointer!==undefined){
      // we're in a lock pointer message
      setPointerLock(data.lockPointer);
    }
    // handle a request for what NFTs the user holds
    if(data.requestTokens){
      // TODO send to match pw format
      sendToMainPage({ cmd: "sendToUe4", value: { tokenList } });
    }
    // a request that requires data be sent to the backend will make use of sendToMainPage
  }
  // two ways to do this. docs have this
  window.e3ds?.onEvent("jsonMessage", handleLeapnMessage);
  // but their sample code has a window.addEventListener like so
  window.addEventListener("jsonMessage", handleLeapnMessage);

  const initiateGame = () => {
    setShowPlayButton(false);
  }

  // TODO review, refactor, relocate (own file in blockchain?)
  const connectMetamask = async () => {
    // can't run this if the worldConfig has not been loaded
    if(!worldConfig) return;
    setIsConnecting(true);
    // await window.ethereum.enable();
    try{
      await window.ethereum.request({
        method: 'eth_requestAccounts'
      });
    }catch(ethError){
      // have to cast as the error has to be unknown above
      const castError = (ethError as any);
      if(castError['code']===4001){
        alert('user rejected request');
      }else{
        alert(`Error connecting to ETH: ${ethError}`);
      }
      setIsConnecting(false);
      return;
    }
    // check to make sure we're on ETH Mainnet
    if(window.ethereum.chainId!=='0x1' && !(process.env.NODE_ENV==='development' && window.ethereum.chainId==='0x4')){
      alert(`${worldConfig.worldName} is built on Ethereum Mainnet. Switch networks to proceed.`);
      // still allow rinkeby
      if(window.ethereum.chainId!=='0x4'){
        setIsConnecting(false);
        return;
      }
    }
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const accounts = await provider.listAccounts();
    
    const collectedInfo: WalletInfo = {};
    try{
      if(!userInfo.address || userInfo.address!==accounts[0]){
        setScanningWallet(true);
        console.log('account list',accounts);
        console.log('processing address',accounts[0]);
        // const approvedWallet = checkApprovedWallet(accounts[0], adminWalletList);
        collectedInfo.address = accounts[0];
        collectedInfo.ensName = await provider.lookupAddress(collectedInfo.address) ?? undefined;
        collectedInfo.allowAccess = checkApprovedWallet(accounts[0], adminWalletList);
        // do the rinkeby "machine elves" check
        if(window.ethereum.chainId==='0x4'){
          const devElf = await checkRinkebyFakeElves(provider, accounts[0]);
          collectedInfo.hasElf = devElf;

          collectedInfo.hasLoaded=true;
          setUserInfo(collectedInfo);
          if(process.env.NODE_ENV !== 'development' && window.location.hostname !== 'test.leapn.life'){
            console.log('rinkeby not authorized in this environment');
            return;
          }
        }else{
          // check the machine elf as we want that for in-game purposes
          collectedInfo.hasElf = await checkMachineElves(provider, accounts[0]);
          // first, check to see if we are doing any kind of contract auth or just approving blindly
          if(!worldConfig.useContractAuth){
            console.log('setting allowAccess to true based upon not using contract auth');
            collectedInfo.allowAccess=true;
          }else if(!collectedInfo.allowAccess){
            // this first check is just the LeapN contracts based upon clientContractCustomization
            // success on that shortcuts making the slower calls through the client contracts            
            // if you are either a founder holder or on the approved list, we return based upon that
            if(
              await checkLeapnApproval(worldConfig, provider, accounts[0])
            ){
              collectedInfo.allowAccess=true;
            }else{
              // check the client ERC721; return if true, otherwise check ERC1155 as well
              const client721: ContractInfo = {
                type: ContractType.ERC721,
                isUsed: worldConfig.contractCustomization.useERC721Contract ?? false,
                address: worldConfig.contractCustomization.ERC721ContractAddress ?? undefined,
                abi: worldConfig.contractCustomization.ERC721ContractAbi ?? undefined
              };
              
              const client1155: ContractInfo = {
                type: ContractType.ERC1155,
                isUsed: worldConfig.contractCustomization.useERC1155Contract ?? false,
                address: worldConfig.contractCustomization.ERC1155ContractAddress ?? undefined,
                abi: worldConfig.contractCustomization.ERC1155ContractAbi ?? undefined,
                tokenIds: worldConfig.contractCustomization.tokenIdList ?? undefined
              }
              const clientHolder = await checkClientContract(provider, accounts[0], client721, client1155);
              collectedInfo.hasClientToken=clientHolder;
              if(clientHolder){
                collectedInfo.allowAccess=true;
              }
            }
          }
          collectedInfo.hasLoaded=true;
          setUserInfo(collectedInfo);
        }
        // try a delay
        setTimeout(() => {
          setScanningWallet(false);
        }, 3000);
        // setScanningWallet(false);
      }
    }catch(error){
      console.error('error checking authentications, staying falsey',error);
      setUserInfo({ address: accounts[0], hasLoaded: true, hasError: true });
    }
    setIsConnecting(false);
  };

  const populateTokenList = async (address: string): Promise<void> => {
    let updatedTokens: any[] = [];
    if(worldConfig?.contractCustomization.ERC721ContractAddress){
      const client721List = await getNftsForWalletAndContract(address, worldConfig?.contractCustomization.ERC721ContractAddress);
      if(client721List){
        updatedTokens = updatedTokens.concat(client721List);
      }
    }
    if(worldConfig?.contractCustomization.ERC1155ContractAddress){
      const client1155List = await getNftsForWalletAndContract(address, worldConfig?.contractCustomization.ERC1155ContractAddress);
      // TODO determine how to handle this
      if(client1155List){
        updatedTokens = updatedTokens.concat(client1155List.filter((token: any) => tokenInList(token.token_id, worldConfig.contractCustomization.tokenIdList)));
      }
    }
    setTokenList(updatedTokens);
  };

  // population control methods
  useEffect(() => {
    // console.log('populationNonce changed',populationNonce);
    populationNonceRef.current = populationNonce;
  }, [populationNonce]);

  useEffect(() => {
    // zero is valid here
    if(populationCount!==undefined && worldConfig?.maxPlayers!==undefined){
      if(populationCount<worldConfig.maxPlayers && !isLaunching){
        console.log('launch trigger',populationCount);
        // register and connect?
        makeConnection();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [populationCount, isLaunching, worldConfig]);

  const makeConnection = async () => {
    if(!worldConfig || !userInfo.address) return;
    if(isLoginRunningRef.current){
      console.log('blocked on client, already running');
    }else{
      isLoginRunningRef.current = true;
      // first check that you don't have any other open sessions in this world
      const hasOtherSessions = await checkAddressSessions(worldConfig.worldName, userInfo.address, populationNonce);
      if(hasOtherSessions){
        console.error('user has another session with adddress',userInfo.address);
        setWalletInUse(true);
        return;
      }
      // put ourselves in the database
      await registerPopulation();
      // check that there was not a sudden burst of activity
      await getPlayerPopulation();
      // handle the actual logic in the effect
      setIsLaunching(true);
    }
  }

  const getPlayerPopulation = async () => {
    if(!worldConfig || !userInfo.address) return;
    const playerCountResponse = await queryActiveUsers(worldConfig.worldName, userInfo.address);
    console.log('currently playing',playerCountResponse);
    if(Number.isInteger(playerCountResponse)){
      setPopulationCount(playerCountResponse);
    }else{
      setPopulationCount(undefined);
    }
    if(!populationNonceRef.current){
      populationCheckRef.current = setTimeout(() => getPlayerPopulation(), POPULATION_CHECK_INTERVAL);
    }
  }

  // this effect will make the disconnect if/when the population changes at preset times (the 10s and 20s post launch timeouts)
  // it can only do this while the game has not connected due to blockDisconnect
  useEffect(() => {
    if(isLaunching && worldConfig){
      console.log('pop or block change',populationCount,blockDisconnect);
      if(!blockDisconnect && populationCount && populationCount > worldConfig.maxPlayers){
        alert("Disconnecting due to world population surge");
        deleteUser();
        window.location.reload();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [populationCount, blockDisconnect, isLaunching, worldConfig]);

  const registerPopulation = async () => {
    if(!worldConfig || !userInfo.address) return;
    const playerId = uuidv4();
    const registrationResult = await createUserRecord(worldConfig.worldName, playerId, userInfo.address);
    if(registrationResult){
      console.log('saved registration, working nonce',playerId);
      setPopulationNonce(playerId);
    }else{
      console.error('failed to register player, have to try again');
    }
    // check at 10s and 20s to make sure we haven't blown the population cap
    setTimeout(() => getPlayerPopulation(), 10000);
    setTimeout(() => getPlayerPopulation(), 20000);
    keepAliveRef.current = setTimeout(() => {
      populationPing();
    }, POPULATION_MAINTENANCE_INTERVAL);
  }

  const populationPing = async () => {
    console.log('maintaining population status',populationNonceRef.current);
    if(populationNonceRef.current && worldConfig){
      await updateUserRecord(worldConfig.worldName, populationNonceRef.current);
      // very slow infinite loop
      keepAliveRef.current = setTimeout(() => populationPing(), POPULATION_MAINTENANCE_INTERVAL);
    }else{
      // something went wrong and we need to restart the pings
      await registerPopulation();
    }
  }

  const deleteUser = async () => {
    console.log('delete user called');
    if(populationNonceRef.current && worldConfig){
      await removeUserRecord(worldConfig.worldName, populationNonceRef.current);
      console.log('delete user completed');
    }else{
      console.log('no nonce to delete user by');
    }
  }
  // end population control

  useEffect(() => {
    if(worldConfig){
      document.title = `LeapN | ${worldConfig.worldName}`;
    }
  }, [worldConfig]);

  const contractBooleans = useMemo(() => ({
    useFounders: worldConfig?.useFoundersAuth ?? false,
    useAlphaFounders: worldConfig?.useAlphaFoundersAuth ?? false,
    useGoldnVip: worldConfig?.useGoldnVipAuth ?? false,
    useRoyalVip: worldConfig?.useRoyalVipAuth ?? false,
    useErc1155: worldConfig?.contractCustomization.useERC1155Contract ?? false,
    useErc721: worldConfig?.contractCustomization.useERC721Contract ?? false
  }), [worldConfig]);

  const baseProps = {
    backgroundImage,
    clientLogo,
    worldName: worldConfig?.worldName ?? 'config error',
    logoAlt: worldConfig?.logoAltText ?? 'config error',
    leapnLogo: "leapn_alpha.png",
    hideLeapnLogo: worldConfig?.hideLeapnLogo ?? false
  };
  const noTokenProps= {
    ...baseProps,
    clientNfts: worldConfig?.clientNfts,
    ...contractBooleans,
    customText: "LeapN Founders Deed World holders will be able to enter soon."
  }
  // optional configuration: buttonColor (defaults to ape-city green #456633)
  const connectProps = {
    ...baseProps,
    isConnecting,
    checkboxBackground: worldConfig?.checkboxBackground ?? 'transparent',
    textColor: worldConfig?.textColor ?? '#454444',
    buttonColor: worldConfig?.buttonColor ?? undefined,
    checkedColor: worldConfig?.checkedColor ?? undefined,
    linkTextColor: worldConfig?.linkTextColor ?? undefined,
    buttonTextColor: worldConfig?.buttonTextColor ?? undefined,
    scanningWallet,
    connectMetamask
  }
  const populationProps = {
    ...baseProps,
    walletInUse,
    textColor: worldConfig?.textColor ?? '#454444'
  }

  if(!worldConfig){ return <div>Something's not quite right. No world config found.</div> }
  
  if(userInfo.address || !worldConfig?.useWallet){
    if(userInfo.allowAccess || !worldConfig.useWallet){
      // inject a button and pop cx here
      if(isLaunching){
        const gameData: GameProps = {
          streamUrl: worldConfig?.gameUrl,
          queuePosition,
          acquirePercent: acquisitionPercent,
          prepPercent: preparationPercent,
          showPlayButton,
          isFull,
          loadingImage,
          displayAddress: userInfo.ensName ? userInfo.ensName : userInfo.address,
          worldName: worldConfig.worldName,
          leapnLogo: "leapn_alpha.png",
          logoAlt: worldConfig.logoAltText,
          hideTopLeftLeapn: worldConfig.hideLeapnLogo,
          initiateGame
        }
        return <Game {...gameData} />
      }else if(walletInUse || (populationCount!==undefined && (populationCount >= worldConfig.maxPlayers))){
        return <PopulationFail {...populationProps} />
      }else{
        return <Connect {...connectProps} />
      }
    }else{
      return <NoTokenMessage {...noTokenProps} />
    }
  }else{
    return <Connect {...connectProps} />
  }
}

export default App;
