import React from 'react';
import "./TopLeftStack.css";

type TopLeftStackProps = {
  worldName: string;
  logoImage: string;
  logoAlt: string;
}
const TopLeftStack = (props: TopLeftStackProps) => (
  <div className="top_left_stack">
    <a href="https://leapn.life/" target="_blank" rel="noreferrer">
      <img src={props.logoImage} alt={props.logoAlt} width={170} />
    </a>
    <p>{ props.worldName }</p>
  </div>
);

export default TopLeftStack;